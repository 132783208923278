import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'
import ecosystemRoutes from './ecosystems'
import structuralDiversityRoutes from './structuralDiversities'
import irrigationTypes from './irrigationTypes'

import materialRoutes from './materials'
import nurseryRoutes from './nurseries'
import plantingTypes from './plantingTypes'
import assemblyRoutes from './assemblies'
import transportationRoutes from './transportation'

const Admin = () => import('../views/admin/Admin.vue')
const Calculator = () => import('../views/calculator/Calculator.vue')
const CategoryEditor = () => import('../views/admin/categories/CategoryEditor')
const Dashboard = () => import('../views/admin/dashboard/Dashboard')

const Guide = () => import('../views/guide/Guide.vue')
const Landing = () => import('../views/landing/Landing.vue')
const MyProjects = () => import('../views/myProjects/MyProjects.vue')
const NewProject = () => import('../views/newProject/NewProject.vue')

const PasswordReset = () => import('../views/passwordReset/PasswordReset.vue')
const ProductTemplateForm = () => import('../views/admin/templates/ProductTemplateForm')
const Project = () => import('../views/project/Project.vue')
const ProjectLocation = () => import ('../views/projectLocation/ProjectLocation.vue')
const Projects = () => import('../views/admin/projects/Projects')
const Scripts = () => import('../views/admin/scripts/Scripts')
const SignIn = () => import('../views/signIn/SignIn.vue')
const TemplateForm = () => import('../views/admin/templates/templateForm/TemplateForm')
const TemplateList = () => import('../views/admin/templates/TemplateList')
const Variables = () => import('../views/admin/variables/Variables')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    // redirect: '/projects',
    component: Landing,
    meta: {
      requiresAuthStatus: null,
      page: {
        title: '',
        content: 'dark',
        toolbar: 'dark',
        showToolbar: false
      }
    }
  },
  {
    path: '/start',
    name: 'register',
    component: SignIn,
    meta: {
      requiresAuthStatus: false,
      page: {
        showToolbar: false,
        content: 'dark'
      }
    }
  },
  {
    path: '/reset_password',
    name: 'password_reset',
    component: PasswordReset,
    meta: {
      requiresAuthStatus: false,
      page: {
        showToolbar: false,
        content: 'dark'
      }
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: MyProjects,
    meta: {
      requiresAuthStatus: true,
      page: {
        showToolbar: false,
        title: 'My Projects',
        toolbar: 'dark',
        content: 'dark'
      }
    }
  },
  {
    path: '/projects/new',
    name: 'new-project',
    component: NewProject,
    meta: {
      requiresAuthStatus: true,
      page: {
        title: 'New Project',
        toolbar: 'dark',
        content: 'dark'
      }
    }
  },
  {
    path: '/projects/:id',
    name: 'project',
    component: Project,
    props: true,
    meta: {
      requiresAuthStatus: true,
      page: {
        toolbar: 'white',
        content: 'white',
        transparentToolbar: true,
        showBackArrowOnMobile: true
      }
    }
  },
  {
    path: '/projects/:id/location',
    name: 'project-location',
    component: ProjectLocation,
    props: route => ({
      projectId: route.params.id
    }),
    meta: {
      requiresAuthStatus: true,
      page: {
        showToolbar: false
      }
    }
  },
  {
    path: '/guide',
    component: Guide,
    meta: {
      requiresAuthStatus: null,
      page: {
        showToolbar: false,
        content: 'dark'
      }
    }
  },
  {
    path: '/location',
    name: 'location',
    component: ProjectLocation,
    meta: {
      requiresAuthStatus: null,
      page: {
        showToolbar: false
      }
    }
  },
  {
    path: '/projects/:id/edit',
    name: 'calculator',
    component: Calculator,
    props: route => ({
      projectId: route.params.id,
      versionId: route.query.version
    }),
    meta: {
      requiresAuthStatus: true,
      page: {
        title: '',
        toolbar: 'dark',
        content: 'light'
      }
    }
  },
  {
    path: '/admin',
    component: Admin,
    meta: {
      requiresAuthStatus: 'admin',
      page: {
        toolbar: 'dark',
        content: 'light',
        title: 'Admin',
        backButtonPath: '/projects'
      }
    },
    children: [
      {
        path: '',
        name: 'admin',
        component: Dashboard,
        meta: {
          requiresAuthStatus: 'admin'
        }
      },
      {
        path: 'templates',
        name: 'templates',
        component: TemplateList,
        meta: { requiresAuthStatus: 'admin' }
      },
      {
        path: 'templates/new',
        name: 'newTemplate',
        component: TemplateForm,
        meta: { requiresAuthStatus: 'admin' }
      },
      {
        path: 'templates/:id',
        name: 'editTemplate',
        component: TemplateForm,
        props: true,
        meta: { requiresAuthStatus: 'admin' }
      },
      {
        path: 'templates/products/new',
        component: ProductTemplateForm,
        name: 'newProduct'
      },
      {
        path: 'templates/products/:id',
        component: ProductTemplateForm,
        name: 'editProduct',
        props: true
      },
      {
        path: 'categories',
        name: 'categories',
        component: CategoryEditor,
        meta: { requiresAuthStatus: 'admin' }
      },
      {
        path: 'variables',
        meta: {
          requiresAuthStatus: 'admin'
        },
        component: Variables
      },
      ...ecosystemRoutes,
      ...structuralDiversityRoutes,
      ...irrigationTypes,
      ...materialRoutes,
      ...nurseryRoutes,
      ...plantingTypes,
      ...assemblyRoutes,
      ...transportationRoutes,
      {
        path: 'projects',
        meta: {
          requiresAuthStatus: 'admin'
        },
        component: Projects
      },
      {
        path: 'scripts',
        meta: {
          requiresAuthStatus: 'admin'
        },
        component: Scripts
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router

router.beforeEach(async (to, from, next) => {
  const requiresAuthStatus = to.meta.requiresAuthStatus

  if (requiresAuthStatus) {
    const { data: user } = await Vue.prototype.$axios.get('/session')
    await store.dispatch('auth/setUserAuthenticated', user)
  }

  next()
})

router.afterEach((to, from) => {
  let { page } = to.meta
  if (!page) {
    page = to.matched[0] ? to.matched[0].meta.page : null
  }
  const pageDefaults = {
    showToolbar: true,
    editableTitleAction: null,
    toolbar: 'dark',
    content: 'light',
    title: '',
    backButtonPath: '',
    showUserOptions: true,
    showBackArrowOnMobile: false,
    transparentToolbar: false
  }
  if (page) {
    store.commit('setPage', {
      ...pageDefaults,
      ...page
    })
  }
})
