import Vue from 'vue'
import Vuex from 'vuex'

import colors from '@/schema/colors'

import editingElementModule from './editingElement'
import projectModule from './projects'
import authModule from './auth'
import listsModule from './lists'

Vue.use(Vuex)

let snackbarTimeout = null

const getDimensionUnitMappings = (unitsConfig) => {
  return unitsConfig.map((x) => {
    const ret = { ...x }
    if (ret.symbol === 'unit') {
      ret.symbol = 'quantity'
      ret.desc = 'quantity'
    }
    return ret
  })
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    systemOfMeasurement: 'metric',
    snackbar: {
      color: 'shamrock',
      content: null
    },
    colors: colors,
    page: {
      showToolbar: true,
      toolbar: 'dark',
      content: 'dark',
      title: '',
      backButtonPath: ''
    },
    configs: {},
    win: {
      width: 0,
      height: 0
    },
    showTooltips: true,
    userId: null,
    isOnboarding: false,
    oldAppUrl: (() => {
      const { hostname, port } = window.location
      if (hostname === 'app.climatepositivedesign.com' || hostname === 'beta.climatepositivedesign.com') {
        return 'http://legacy.climatepositivedesign.com'
      } else if (hostname === 'localhost') {
        return `http://localhost:808${Number(!+port.substr(-1))}`
      } else {
        return 'http://lcc-staging.surge.sh'
      }
    })(),
    hasSeenPopupOnProjectsPage: false
  },
  getters: {
    userId: state => state.userId,
    isAuthenticated: state => Boolean(state.userId),
    systemOfMeasurement: state => state.systemOfMeasurement,
    oldAppUrl: state => state.oldAppUrl,

    projectTypes: state => state.configs.projectTypes || [],
    projectBiomes: state => state.configs.projectBiomes || {},
    constants: state => state.configs.constants || {},
    cpdConfiguration: state => state.configs.cpdConfiguration,
    ec3Configuration: state => state.configs.ec3Configuration || {},
    regionCodes: state => state.configs.regionCodes || [],
    unitMappings: state => state.configs.units || [],
    dimensionUnitMappings: (state) => getDimensionUnitMappings(state.configs.units),
    getEquivalentUnits: state => (unitSymbol) => {
      const equivalentUnits = {
        ...Object.fromEntries(state.configs.equivalentUnits),
        ...Object.fromEntries(state.configs.equivalentUnits.map(x => [...x].reverse())),
        yd: 'm',
        yd2: 'm2',
        yd3: 'm3'
      }
      const equivalentUnitSymbol = equivalentUnits[unitSymbol]
      return [...state.configs.units, ...getDimensionUnitMappings(state.configs.units)].find(x => x.symbol === equivalentUnitSymbol)
    }
  },
  mutations: {
    setIsOnboarding (state, payload) {
      state.isOnboarding = payload
    },
    setSnackbarVisible (state, payload) {
      state.snackbar.content = payload.text
      state.snackbar.color = payload.color || 'shamrock'
    },
    hideSnackbar (state, payload) {
      state.snackbar.content = null
    },
    setWin (state, payload) {
      state.win = payload
    },
    setPage (state, payload) {
      state.page = {
        ...state.page,
        ...payload,
        editableTitleAction: payload.editableTitleAction || null,
        showToolbar: payload.showToolbar !== undefined ? payload.showToolbar : true
      }
    },
    toggleTooltips (state) {
      state.showTooltips = !state.showTooltips
    },
    setUserId (state, payload) {
      state.userId = payload
    },
    setSystemOfMeasurement (state, payload) {
      state.systemOfMeasurement = payload
    },
    popupDisplayed (state) {
      state.hasSeenPopupOnProjectsPage = true
    },
    setConfigs (state, payload) {
      state.configs = payload
    }
  },
  actions: {
    showSnackbar ({ commit }, payload) {
      if (snackbarTimeout) {
        clearTimeout(snackbarTimeout)
      }
      payload = typeof payload === 'string' ? { text: payload } : payload
      commit('setSnackbarVisible', payload)
      snackbarTimeout = setTimeout(() => {
        commit('hideSnackbar')
      }, payload.timeout || 3000)
    },
    async fetchConfigs ({ commit }) {
      const res = await this._vm.$axios.get(`/configs`)
      commit('setConfigs', res.data)
    }
  },
  modules: {
    elements: editingElementModule,
    projects: projectModule,
    auth: authModule,
    lists: listsModule
  }
})
