const Assembly = () => import('../views/admin/assemblies/Assembly.vue')
const AssemblyForm = () => import('../views/admin/assemblies/AssemblyForm.vue')

export default [
  {
    path: 'assemblies/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: AssemblyForm
  },
  {
    path: 'assemblies',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: Assembly
  }
]
