import firstLetterUppercase from '@/helpers/firstLetterUppercase'
import getUniqueRef from '@/helpers/getUniqueRef'

import { convertImperialDensityToMetric } from '@/helpers/unitConversions'

import Vue from 'vue'

const elementSchema = {
  name: '',
  description: '',
  category: null,
  suggestionsCategory: null,
  type: 'material',
  co2MeasuredPer: 'unit',
  density: undefined,
  areaPerUnit: undefined,
  shadeArea: undefined,
  layouts: [],
  replacements: 0,
  showReplacements: true,
  formulaForCarbonStored: undefined,
  formulaForOneTime: undefined,
  formulaForOperational: undefined,
  formulaForGivenYear: undefined,
  formulaForWaterUse: undefined,
  percentOfCover: undefined,
  sinkFormula: undefined,
  relatedElements: [],
  carbonConscienceIds: [],
  defaultIrrigationTypeOption: undefined,
  defaultStructuralDiversities: undefined,
  defaultMaterialOption: undefined,
  defaultNurseryOption: undefined,
  defaultPercentNative: undefined,
  defaultPlantingTypeOption: undefined,
  defaultTransportationOption: undefined,
  defaultTransportationDistanceOption: undefined,
  ecosystem: undefined,
  showPercentNative: null,
  showTargetCondition: null,
  irrigationTypes: [],
  materialCategories: [],
  materialUseCategories: [],
  materials: [],
  nurseries: [],
  assemblies: [],
  plantingTypes: [],
  structuralDiversities: [],
  cpdVersion: 3
}

export default {
  namespaced: true,
  state: {
    element: elementSchema,
    impactType: 0,
    isFormulaMode: false,
    activeLayout: null,
    suggestedLayouts: [],
    showPercentNative: null,
    showTargetCondition: null,
    templateInUse: false,
    carbonConscienceIds: [],
    irrigationTypes: [],
    structuralDiversities: [],
    materials: [],
    materialUseCategories: [],
    nurseries: [],
    assemblies: [],
    plantingTypes: [],
    ecosystem: null
  },
  getters: {
    systemOfMeasurement: (state, getters, rootState) => rootState.systemOfMeasurement,
    element: state => state.element,
    impactType: state => state.impactType,
    carbonConscienceIds: state => state.carbonConscienceIds,
    irrigationTypes: state => state.irrigationTypes,
    structuralDiversities: state => state.structuralDiversities,
    materials: state => state.materials,
    nurseries: state => state.nurseries,
    assemblies: state => state.assemblies,
    showPercentNative: state => state.showPercentNative,
    plantingTypes: state => state.plantingTypes,
    ecosystem: state => state.ecosystem,
    materialUseCategories: state => state.materialUseCategories,
    isFormulaMode: state => state.isFormulaMode,
    activeLayout: state => state.activeLayout,
    suggestedLayouts: state => state.suggestedLayouts,
    templateInUse: state => state.templateInUse
  },
  mutations: {
    // automatically create setter mutations for each property in element schema
    ...Object.fromEntries(Object.keys(elementSchema).map(key => {
      const setterFunctionName = `set${firstLetterUppercase(key)}`
      const setterFunction = (state, payload) => {
        state.element[key] = payload
      }
      return [setterFunctionName, setterFunction]
    })),
    resetElementState (state) {
      state.element = elementSchema
      state.isFormulaMode = false
      state.impactType = 0

      state.templateInUse = false
    },
    setElement (state, payload) {
      state.element = payload.element
    },
    setImpactType (state, payload) {
      state.impactType = payload
    },
    setFormulaMode (state, payload) {
      state.isFormulaMode = payload
    },
    addEmptyLayout (state) {
      state.element.layouts.push({
        dimensions: [],
        formula: ''
      })
    },
    addLayout (state, payload) {
      state.element.layouts.push(payload)
    },
    setActiveLayout (state, payload) {
      state.activeLayout = payload
    },
    settemplateInUse (state, { templateInUse }) {
      state.templateInUse = templateInUse
    },
    deleteLayout (state, payload) {
      const index = payload
      state.activeLayout = null
      state.element.layouts.splice(index, 1)
    },
    setSuggestedLayouts (state, payload) {
      state.suggestedLayouts = payload
    },
    setFormulaForLayout (state, payload) {
      const { formula, error, index, custom } = payload
      Vue.set(state.element.layouts, index, {
        ...state.element.layouts[index],
        formula: formula || '',
        invalid: Boolean(error),
        customFormula: custom || false
      })
    },
    addNewDimensionToActiveLayout (state) {
      state.element.layouts[state.activeLayout].dimensions.push({
        label: '',
        type: 'length',
        ref: '$l',
        units: {
          metric: {
            symbol: 'm',
            toSi: 1
          },
          imperial: {
            symbol: 'ft',
            toSi: 0.3048
          }
        },
        valueRange: [0, 100],
        editable: true,
        defaultValue: null,
        fixedValueFormula: null
      })
    },
    deleteDimensionFromActiveLayout (state, payload) {
      state.element.layouts[state.activeLayout].dimensions.splice(payload, 1)
    },
    updateDimensioninActiveLayout (state, payload) {
      const { index, newEntries } = payload
      Vue.set(state.element.layouts[state.activeLayout].dimensions, index, {
        ...state.element.layouts[state.activeLayout].dimensions[index],
        ...newEntries
      })
    }
  },
  actions: {
    async loadExistingElement ({ commit, rootGetters }, payload) {
      const { id, systemOfMeasurement } = payload
      const { data: { element, templateInUse } } = await this._vm.$axios.get(`/elements/${id}`, {
        params: {
          system: systemOfMeasurement || rootGetters.systemOfMeasurement
        }
      })
      commit('settemplateInUse', { templateInUse })
      commit('setElement', { element })
      commit('setImpactType', element.formulaForOneTime ? 0 : 1)
    },
    updateImpactType ({ commit, state, dispatch }, payload) {
      // impact is set for plants and can not change
      if (state.element.type === 'plant') return

      const type = payload
      let updatedPayload = {}
      if (type === 0) {
        updatedPayload = {
          formula: (state.element.formulaForOneTime || state.element.formulaForGivenYear),
          key: 'formulaForOneTime'
        }
      } else {
        updatedPayload = {
          formula: (state.element.formulaForOneTime || state.element.formulaForGivenYear),
          key: 'formulaForGivenYear'
        }
      }
      commit('setImpactType', type)
      dispatch('setSpecifiedFormulaAndResetOthers', updatedPayload)
    },
    convertAndSetDensity ({ commit, rootGetters }, payload) {
      const isMetric = rootGetters.systemOfMeasurement === 'metric'
      const res = convertImperialDensityToMetric(payload)
      const calculatedDensity = isMetric ? payload : Math.round(res * 100000) / 100000
      commit('setDensity', calculatedDensity)
    },
    convertAndSetAreaPerUnit ({ commit, rootGetters }, payload) {
      const isMetric = rootGetters.systemOfMeasurement === 'metric'
      const siFactor = rootGetters.unitMappings.find(x => x.symbol === 'sf').toSi
      const calculatedArea = isMetric ? payload : payload * siFactor
      commit('setAreaPerUnit', calculatedArea)
    },
    setSpecifiedFormula ({ commit }, payload) {
      const { key: formulaKey, formula } = payload
      commit(`set${firstLetterUppercase(formulaKey)}`, formula)
    },
    setSpecifiedFormulaAndResetOthers ({ commit }, payload) {
      const { key: formulaKey, formula } = payload
      let keys = ['formulaForOneTime', 'formulaForGivenYear', 'sinkFormula']
      const i = keys.indexOf(formulaKey)
      for (const [index, key] of keys.entries()) {
        if (index === i) {
          commit(`set${firstLetterUppercase(key)}`, formula)
        } else {
          commit(`set${firstLetterUppercase(key)}`, null)
        }
      }
    },
    setParsedElementFormula ({ commit, dispatch, getters }, payload) {
      const { co2PerUnit, formulaType } = payload
      let { formula } = payload
      const { impactType } = getters
      const replacements = (getters.element.showReplacements || getters.element.showReplacements === undefined) ? getters.element.replacements : 0
      switch (impactType) {
        case 0:
          if (co2PerUnit) {
            formula = `$qty * ${co2PerUnit || 0} * ${1 + replacements}`
          }
          dispatch('setSpecifiedFormulaAndResetOthers', { formula, key: 'formulaForOneTime' })
          break
        case 1:
          if (co2PerUnit) {
            formula = `$qty * $yrs * ${co2PerUnit || 0}`
          }
          if (formulaType === 1) {
            // if plant formula
            dispatch('setSpecifiedFormulaAndResetOthers', { formula, key: 'sinkFormula' })
          } else {
            dispatch('setSpecifiedFormulaAndResetOthers', { formula, key: 'formulaForGivenYear' })
          }
          break
      }
    },
    createLayout ({ commit, dispatch, getters }) {
      if (getters.element.layouts.length > 3) {
        const snackbarInfo = { color: 'warning', text: 'Whoops, an element cannot have more than three layouts. Consider using separate templates.' }
        dispatch('showSnackbar', snackbarInfo, { root: true })
      } else {
        commit('addEmptyLayout')
      }
    },
    async getPredictedLayoutFormula (actions, payload) {
      const { dimensions, co2MeasuredPer } = payload
      try {
        const { data } = await this._vm.$axios.post('/utility/get-layout-formula', {
          dimensions,
          co2MeasuredPer
        })
        return data
      } catch (err) {
        return { error: true, formula: '' }
      }
    },
    async predictLayoutFormula ({ commit, getters, dispatch }, payload) {
      const index = payload
      const layout = getters.element.layouts[index]
      const { error, formula } = await dispatch('getPredictedLayoutFormula', { dimensions: layout.dimensions, co2MeasuredPer: getters.element.co2MeasuredPer })
      if (error) {
        const snackbarInfo = { color: 'warning', text: 'Unable to predict a formula from layout dimensions. Please click the pencil icon and enter a formula manually instead.' }
        dispatch('showSnackbar', snackbarInfo, { root: true })
      }
      commit('setFormulaForLayout', { formula, error, index })
    },
    async predictActiveLayoutFormula ({ getters, dispatch }) {
      dispatch('predictLayoutFormula', getters.activeLayout)
    },
    async predictAllLayoutFormulas ({ getters, dispatch }) {
      for (let i = 0; i < getters.element.layouts.length; i++) {
        dispatch('predictLayoutFormula', i)
      }
    },
    async addSuggestedLayoutToElement ({ getters, rootGetters, commit, dispatch }, payload) {
      if (getters.element.layouts.length > 2) {
        const snackbarInfo = { color: 'warning', text: 'Maximum layouts reached. Try deleting a layout first.' }
        dispatch('showSnackbar', snackbarInfo, { root: true })
        return
      }
      let layout = JSON.parse(JSON.stringify(payload))
      // find scientific units from dimension type and get imperial equivalent
      layout.dimensions = layout.dimensions.map(dim => {
        const metricUnit = rootGetters.unitMappings.find(x => x.type === dim.type && x.toSi === 1)
        dim.units = {
          metric: metricUnit,
          imperial: rootGetters.getEquivalentUnits(metricUnit.symbol)
        }
        dim.editable = true
        dim.valueRange = [0, 100]
        dim.fixedValueFormula = null
        return dim
      })
      const { formula } = await dispatch('getPredictedLayoutFormula', { dimensions: layout.dimensions, co2MeasuredPer: getters.element.co2MeasuredPer })
      commit('addLayout', { ...layout, formula })
    },
    createDimension ({ getters, dispatch, commit }) {
      if (getters.element.layouts[getters.activeLayout].dimensions.length > 10) {
        const snackbarInfo = { color: 'warning', text: "An element can't have more than five dimensions" }
        dispatch('showSnackbar', snackbarInfo, { root: true })
        return
      }
      commit('addNewDimensionToActiveLayout')
      dispatch('predictActiveLayoutFormula')
    },
    deleteDimension ({ commit, dispatch }, payload) {
      commit('deleteDimensionFromActiveLayout', payload)
      dispatch('predictActiveLayoutFormula')
    },
    updateDimensionUnit ({ getters, dispatch, commit }, payload) {
      const { index, units, type } = payload

      // get all existing dimensions of layout
      const allDimensions = getters.element.layouts[getters.activeLayout].dimensions

      let label = allDimensions[index].label
      // get label
      if (!label) {
        if (type === 'count') {
          label = 'Units'
        } else if (type === 'custom') {
          label = 'My dimension'
        } else {
          const capitalize = s => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase()
          label = capitalize(type)
        }
      }

      // set existing refs array to all layout refs except the ref of the dimension being updated
      const existingRefs = allDimensions.map(x => x.ref)
      existingRefs.splice(index, 1)

      const newEntries = {
        // extend current dimension
        type,
        units,
        label,
        // if label is undefined, random letter is used for ref
        ref: getUniqueRef(label, existingRefs)
      }

      commit('updateDimensioninActiveLayout', { index, newEntries })
      dispatch('predictActiveLayoutFormula')
    },
    updateDimensionLabel ({ getters, commit, dispatch }, payload) {
      const { index, label } = payload
      const allDimensions = getters.element.layouts[getters.activeLayout].dimensions
      if (!label.length) {
        const snackbarInfo = { color: 'warning', text: 'Label name cannot be blank' }
        return dispatch('showSnackbar', snackbarInfo)
      }
      const existingRefs = allDimensions.map(x => x.ref)
      const newEntries = {
        label,
        ref: getUniqueRef(label, existingRefs)
      }
      commit('updateDimensioninActiveLayout', { index, newEntries })
      dispatch('predictActiveLayoutFormula')
    }
  }
}
